import http from "../http-common";

class MapQueuesService {
    getmap_queuess() {
        return http.get('/map_queues');
    }
    getmap_queues(id) {
        return http.get('/map_queues/' + id);
    }
    createmap_queues(data) {
        return http.post('/map_queues', data);
    }
    updatemap_queues(id, data) {
        return http.put(`/map_queues/${id}`, data);
    }
}

export default new MapQueuesService();