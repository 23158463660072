<template>
  <div class="container">

                <div class="form-group mt-3">
                  <label for="username">เวลาเปิดจองคิว</label>
                  <input
                    v-model="user.starttime"
                    type="time"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                    placeholder="กรุณากรอกสถานะ"
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="username">เวลาปิดจองคิว</label>
                  <input
                    v-model="user.endtime"
                    type="time"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                    placeholder="กรุณากรอกสถานะ"
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="username">ลำดับคิว</label>
                  <input
                    v-model="user.queueno"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                    placeholder="กรุณากรอกลำดับคิว"
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="username">จำนวนหลักคิว</label>
                  <input
                    v-model="user.count"
                    type="number"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                    placeholder="กรุณากรอกจำนวนหลักคิว"
                  />
                </div>
              <button type="button" class="btn btn-success" @click="save()">
              บันทึก
            </button>
  </div>
</template>

<script>
import TimesService from "../services/TimesService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      list: [],
      user: {},
      user_id: 0,
      title: "",
      roles:[]
    };
  },
  mounted() {
    this.user_id = 1
    this.getUsers()
  },
  methods: {
    getUsers(){

      TimesService.gettime(this.user_id).then((res) => {
          // console.log(res.data);
          this.user = res.data;
        });
    },
    save() {
      if (this.user.starttime == "" || this.user.starttime == null) {
        alert("กรุณากรอกเวลาเปิดจองคิว");
      }else if (this.user.endtime == "" || this.user.endtime == null) {
        alert("กรุณากรอกเวลาปิดจองคิว");
      }else if (this.user.queueno == "" || this.user.queueno == null) {
        alert("กรุณากรอกลำดับคิว");
      }else if (this.user.count == "" || this.user.count == null) {
        alert("กรุณากรอกจำนวนหลักคิว");
      }else {
        var userdata = {
          starttime: this.user.starttime,
          endtime:this.user.endtime,
          queueno:this.user.queueno,
          count:this.user.count,
        };
        if (this.user_id == 0) {
          TimesService.createtime(userdata).then(() => {
            alert('บันทึกสำเร็จ')
            this.getUsers();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        } else {
          // console.log(this.user_id);
          TimesService.updatetime(this.user_id, userdata).then(() => {
            // console.log(res.data);
            alert('บันทึกสำเร็จ')
            this.getUsers();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        }
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
