import http from "../http-common";

class QueuesService {
    getqueues(name,status) {
        return http.get('/queues?name='+name+'&&status='+status);
    }
    getnameQueue(type) {
        return http.get('/queues/getnameQueue?type='+type);
    }
    getqueue(id) {
        return http.get('/queues/' + id);
    }
    createqueue(data) {
        return http.post('/queues', data);
    }
    updatequeue(id, data) {
        return http.put(`/queues/${id}`, data);
    }
    updatestatus(id, data) {
        return http.put(`/queues/updatestatus/${id}`, data);
    }
    deletequeue(id,status) {
        return http.delete('/queues/' + id+'/'+status);
    }
}

export default new QueuesService();