import http from "../http-common";

class TypesService {
    gettypes(name) {
        return http.get('/types?name='+name);
    }
    gettype(id) {
        return http.get('/types/' + id);
    }
    createtype(data) {
        return http.post('/types', data);
    }
    updatetype(id, data) {
        return http.put(`/types/${id}`, data);
    }
    deletetype(id,status) {
        return http.delete('/types/' + id+'/'+status);
    }
}

export default new TypesService();