import Vue from "vue";
import Router from "vue-router";
import Login from './components/Login.vue';
import Users from './components/Users.vue';
import Menu from './components/Menu.vue';
import Roles from './components/Roles.vue';
import Register from './components/Register.vue';
import ActivateEmail from './components/ActivateEmail.vue';
import line from './components/Line.vue';
import LoginLine from './components/LoginLine.vue';
import Types from './components/Types.vue';
import Status from './components/Status.vue';
import Menumanage from './components/Menumanage.vue';
import Times from './components/Times.vue';
import Queue from './components/Queue.vue';
import Lists from './components/Lists.vue';
import Qrcode from './components/Qrcode.vue';
import Myqueue from './components/Myqueue.vue';
import Book from './components/Book.vue';
import AllLists from './components/AllLists.vue';

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/login",
      alias: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/user",
      alias: "/user",
      name: "user",
      component: Users
    },
    {
      path: "/role",
      alias: "/role",
      name: "role",
      component: Menu
    },
    {
      path: "/roles",
      alias: "/roles",
      name: "roles",
      component: Roles
    },
    {
      path: "/register",
      alias: "/register",
      name: "register",
      component: Register
    },
    {
      path: "/activateemail/:id",
      alias: "/activateemail",
      name: "activateemail",
      component: ActivateEmail
    },
  {
    path: "/line",
    alias: "/line",
    name: "line",
    component: line
  },
  {
    path: "/loginline",
    alias: "/loginline",
    name: "loginline",
    component: LoginLine
  },
  {
    path: "/types",
    alias: "/types",
    name: "types",
    component: Types
  },
  {
    path: "/status",
    alias: "/status",
    name: "status",
    component: Status
  },
  {
    path: "/managemenu",
    alias: "/managemenu",
    name: "managemenu",
    component: Menumanage
  },
  {
    path: "/times",
    alias: "/times",
    name: "times",
    component: Times
  },
  // {
  //   path: "/",
  //   alias: "/queue",
  //   name: "queue",
  //   component: Queue
  // },
  {
    path: "/queue",
    alias: "/queue",
    name: "queue",
    component: Queue
  },
  {
    path: "/list",
    alias: "/list",
    name: "list",
    component: Lists
  },
  {
    path: "/qrcode",
    alias: "/qrcode",
    name: "qrcode",
    component: Qrcode
  },
  {
    path: "/myqueue",
    alias: "/myqueue",
    name: "myqueue",
    component: Myqueue
  },
  {
    path: "/",
    alias: "/book",
    name: "book",
    component: Book
  },
  {
    path: "/alllists",
    alias: "/alllists",
    name: "alllists",
    component: AllLists
  },
  ]
});

Vue.use(Router);

router.beforeEach((to, from, next) => {
  var page = to.path.split("/")
  var qrcode = ''
  if (page[1] == 'activateemail' || page[1] == 'line' || page[1] ==  'permissiondocpb') {
    qrcode = to.path
  }
  // console.log(page[1]);
  // console.log(qrcode);
  const publicPages = ['/', '/login', '/list','/qrcode', qrcode];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('userqueuevidm');
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('queue');
  } else {
    next();
  }

});

export default router;
