<template>
  <div class="container-fluid">
    <h6 class="mt-5 mb-3">รายการคิวตรวจสภาพรถวันที่ {{date}}</h6>
    <table class="table table-bordered">
      <thead>
        <tr class="table-active">
          <th scope="col">ลำดับคิว</th>
          <!-- <th scope="col">ประเภทรถ</th> -->
          <th scope="col">หมายเลขทะเบียน</th>
          <th scope="col">สถานะ</th>
        </tr>
      </thead>
      <tbody v-if="currentUser && currentUser.role_id != 2">
        <tr v-for="(l, i) in list" :key="i">
          <td :style="'font-weight:bold;color:'+l.color">
            {{ l.nameQueue }}
          </td>
          <!-- <td :style="'font-weight:bold;color:'+l.color">
            {{ l.type }}
          </td> -->
          <td :style="'font-weight:bold;color:'+l.color">
             <div v-for="(ll, ii) in l.license" :key="ii"> {{ ll.name }} </div>
          </td>
          <td :style="'width:30%;font-weight:bold;color:'+l.color">
            <div class="form-group">
                  <select class="form-control form-control-sm" v-model="l.statusId" @change="savestatus(l)">
  <option v-for="(i,r) in status" :key="r" :value="i.id">{{i.name}}</option>
</select>
                </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr v-for="(l, i) in list" :key="i">
          <td :style="'font-weight:bold;color:'+l.color">
            {{ l.nameQueue }}
          </td>
          <!-- <td :style="'font-weight:bold;color:'+l.color">
            {{ l.type }}
          </td> -->
          <td :style="'font-weight:bold;color:'+l.color">
             <div v-for="(ll, ii) in l.license" :key="ii"> {{ ll.name }} </div>
          </td>

          <td :style="'width:30%;font-weight:bold;color:'+l.color">
            {{l.status}}
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modal -->
    <div
      class="modal fade"
      id="AddType"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            
          </div>
          <div class="modal-body">
            <form>
              <div class="card-body mt-3">
                <div class="form-group mt-3">
                  <label for="username">ประเภทรถ</label>
                  <input
                    v-model="user.name"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                    placeholder="กรุณากรอกประเภทรถ"
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="username">จำนวน</label>
                  <input
                    v-model="user.count"
                    type="number"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                    placeholder="กรุณากรอกจำนวน"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-success" @click="save()">
              บันทึก
            </button>
            <button
            id="closeduser"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QueuesService from "../services/QueuesService";
import StatusService from "../services/StatusService";
import LinkImageService from "../services/LinkImageService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      list: [],
      user: {},
      user_id: 0,
      title: "",
      roles:[],
      date:'',
      status:[],
    };
  },
  mounted() {
    // console.log(this.currentUser);
    this.getUsers();
    this.date = this.convertdatetothai()
    this.getstatus()
  },
  methods: {
    sentline(txt,line_token) {
        LinkImageService.sendNotify(
          txt,
          line_token
        );
    },
    savestatus(data){
      var updatestatus = {
        statusId:data.statusId,
        updateBy:this.currentUser.id
      }
      QueuesService.updatestatus(data.id,updatestatus).then(() => {
        // StatusService.getstatus(data.statusId).then((res) => {
          QueuesService.getqueue(data.id).then((res)=>{
          var txt =  res.data.license+res.data.status
        this.sentline(txt,data.line_token)
    this.getUsers();
  });
      });
    },
    getstatus(){
      StatusService.getstatuss('').then((res) => {
          // console.log(res.data);
          this.status = res.data;
        });
    },
    convertdatetothai() {
        const date = new Date();
        const result = date.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
        return result;
    },
    getid(id) {
      this.user_id = id;
      if (this.user_id != 0) {
        this.title = "แก้ไขข้อมูลประเภทรถ";
        // console.log(this.user_id);
        QueuesService.getqueue(this.user_id).then((res) => {
          // console.log(res.data);
          this.user = res.data;
        });
      } else {
        this.title = "เพิ่มข้อมูลผระเภทรถ";
        this.user = [];
      }
    },
    save() {
      if (this.user.name == ""  || this.user.name == null) {
        alert("กรุณากรอกประเภทรถ");
      } else if (this.user.count == "" || this.user.count == null) {
        alert("กรุณากรอกจำนวน");
      } else {
        var userdata = {
          name: this.user.name,
          count: this.user.count,
          status:1
        };
        if (this.user_id == 0) {
          QueuesService.createqueue(userdata).then(() => {
            document.getElementById("closeduser").click();
            this.getUsers();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        } else {
          // console.log(this.user_id);
          QueuesService.updatequeue(this.user_id, userdata).then(() => {
            // console.log(res.data);
            document.getElementById("closeduser").click();
            this.getUsers();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        }
      }
    },
    getUsers() {
      QueuesService.getqueues('',1).then((res) => {
        this.list = res.data;
        // console.log(this.list);
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
