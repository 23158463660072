import http from "../http-common";

class StatusService {
    getstatuss(name) {
        return http.get('/status?name='+name);
    }
    getstatus(id) {
        return http.get('/status/' + id);
    }
    createstatus(data) {
        return http.post('/status', data);
    }
    updatestatus(id, data) {
        return http.put(`/status/${id}`, data);
    }
    updatestatusval(id, data) {
        return http.put(`/status/updatestatus/${id}`, data);
    }
    
    updateorder(id, data) {
        return http.put(`/status/updateorder/${id}`, data);
    }
    deletestatus(id) {
        return http.delete('/status/' + id);
    }
    updateactivestatus() {
        return http.delete('/status/');
    }
}

export default new StatusService();