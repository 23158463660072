<template>
  <div class="container">
    <div class="col mb-3 mt-3" style="text-align: right">
      <a>
        <button
          type="button"
          id="get_file"
          class="btn btn-success"
          @click="getid(0)"
          data-bs-toggle="modal"
          data-bs-target="#AddType"
        >
          <i class="fa fa-plus"></i> เพิ่มสถานะ
        </button></a
      >
    </div>
    <div>
    <table class="table table-bordered">
      <thead>
        <tr class="table-active">
          <th scope="col">ลำดับที่</th>
          <th scope="col">สถานะ</th>
          <th scope="col">สี</th>
          <th scope="col">ค่าเริ่มต้น</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody is="draggable" :list="list" tag="tbody">
        <tr style="cursor: move" v-for="(l, i) in list" :key="i">
          <td>
            {{ i + 1 }}
          </td>
          <td>
            {{ l.name }} 
          </td>
          <td :style="'background-color:'+l.color">
           
          </td>
          <td>
            <div class="form-group">
<div class="custom-control custom-switch">
<input type="checkbox" class="custom-control-input" :id="l.id" v-model="l.active" @change="savestatus(l.id)">
<label class="custom-control-label" :for="l.id"></label>
</div>
</div>
          </td>
          <td>
            <a @click="getid(l.id)">
              <button
                type="button"
                class="btn btn-warning"
                data-bs-toggle="modal"
                data-bs-target="#AddType"
              >
                <i class="fa fa-edit"></i></button
            ></a>&nbsp;
            <a @click="updatestatus(l.id)">
              <button
                type="button"
                class="btn btn-danger"
              >
                <i class="fa fa-trash"></i></button
            ></a>
          </td>
        </tr>
      </tbody>
    </table>

    <div>
        <div style="text-align: center">
          <button
            class="btn btn btn-success btn-sm"
            style="color: white; text-align: center"
            @click="saveorderlist()"
          >
            บันทึก
          </button>
        </div>
        <div class="col-2"></div>
        <div class="col-4"></div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="AddType"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            
          </div>
          <div class="modal-body">
            <form>
              <div class="card-body mt-3">
                <div class="form-group mt-3">
                  <label for="username">สถานะ</label>
                  <input
                    v-model="user.name"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                    placeholder="กรุณากรอกสถานะ"
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="username">สี</label>
                  <input
                  type="color"
                  class="form-control form-control-color"
                  id="exampleColorInput"
                  :value="user.color"
                  @change="selectcolor($event)"
                  title="Choose your color"
                  style="width:20%"
                />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-success" @click="save()">
              บันทึก
            </button>
            <button
            id="closeduser"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusService from "../services/StatusService";
import draggable from "vuedraggable";

export default {
  name: "Nav",
  components: {
    draggable,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      list: [],
      user: {},
      user_id: 0,
      title: "",
      roles:[],
      color: "#0000FF",
    };
  },
  mounted() {
    this.getUsers();
    this.user.color = "#0000FF"
  },
  methods: {
    selectcolor(event) {
      this.user.color = event.target.value;
      console.log(this.user.color);
    },
    saveorderlist() {
      for (let l = 0; l < this.list.length; l++) {
        // console.log(l+1);
        var list = {
          no: l + 1,
        };
        StatusService.updateorder(this.list[l].id, list).then(() => {
          if (l + 1 == this.list.length) {
            alert("บันทึกสำเร็จ");
            setTimeout(function () {
              location.reload();
            }, 500);
          }
        });
      }
    },
    savestatus(id){
      StatusService.updateactivestatus().then(() => {
        StatusService.deletestatus(id).then(() => {
          alert('บันทึกสำเร็จ')
          this.getUsers();
        });
      });
// console.log(id,active);
    },
    updatestatus(id){
      var userdata = {
          status:0
        };
        StatusService.updatestatusval(id,userdata).then(() => {
          alert('บันทึกสำเร็จ')
          this.getUsers();
        });
// console.log(id,active);
    },
    
    getid(id) {
      this.user_id = id;
      if (this.user_id != 0) {
        this.title = "แก้ไขสถานะ";
        // console.log(this.user_id);
        StatusService.getstatus(this.user_id).then((res) => {
          // console.log(res.data);
          this.user = res.data
          this.getUsers();
        });
      } else {
        this.title = "เพิ่มสถานะ";
        this.user = [];
      }
    },
    save() {
      if (this.user.name == "" || this.user.name == null) {
        alert("กรุณากรอกสถานะ");
      }else {
        var userdata = {
          name: this.user.name,
          status:1,
          no:this.list.length+1,
          active:0,
          color:this.user.color
        };
        console.log(userdata);
        if (this.user_id == 0) {
          StatusService.createstatus(userdata).then(() => {
            document.getElementById("closeduser").click();
            alert('บันทึกสำเร็จ')
            this.getUsers();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        } else {
          // console.log(this.user_id);
          StatusService.updatestatus(this.user_id, userdata).then(() => {
            // console.log(res.data);
            document.getElementById("closeduser").click();
            alert('บันทึกสำเร็จ')
            this.getUsers();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        }
      }
    },
    getUsers() {
      StatusService.getstatuss('').then((res) => {
        this.list = res.data;
        // console.log(this.list);
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
