import http from "../http-common";

class TimesService {
    gettimes() {
        return http.get('/times');
    }
    gettime(id) {
        return http.get('/times/' + id);
    }
    createtime(data) {
        return http.post('/times', data);
    }
    updatetime(id, data) {
        return http.put(`/times/${id}`, data);
    }
    deletetime(id,status) {
        return http.delete('/times/' + id+'/'+status);
    }
}

export default new TimesService();