<template>
  <div class="container">
    <div >
      <label class="mt-5" for="username">ประเภทรถ</label>
    <div class="form-check" v-for="(t,i) in types" :key="i">
  <input class="form-check-input" type="radio" :name="t.name" :id="t.name+t.id" :value="t.id" v-model="user.typeId" @change="getlicense()">
  <label class="form-check-label" :for="t.name+t.id">
    {{t.name}}
  </label>
</div>
<div v-if="licensestatus">
                <div class="form-group mt-3" v-for="(l,i) in licenselist" :key="i">
                  <label for="username">หมายเลขทะเบียน {{l.no}}</label>
                  <input
                    v-model="l.license"
                    type="text"
                    class="form-control form-control-sm"
                    id="username"
                    placeholder="กรุณากรอกหมายเลขทะเบียน"
                  />
                </div>


</div>
              <button type="button" class="btn btn-success mt-3" @click="save()" v-if="licensestatus">
              จองคิว
            </button>
<!-- &nbsp;
            <button type="button" class="btn btn-success" @click="connectline()" v-if="!currentUser.line_token">
              เชื่อมต่อไลน์
            </button>  -->
    </div>
    <!-- <div v-else style="text-align:center" class="mt-5">
      <h5>กรุณาลงทะเบียนคิวในเวลาทำการ {{start}} น. - {{end}} น.</h5>
    </div> -->
  </div>
</template>

<script>
import TimesService from "../services/TimesService";
import TypesService from "../services/TypesService";
import QueuesService from "../services/QueuesService";
import LinkImageService from "../services/LinkImageService";
import { DeviceUUID } from 'device-uuid';
import StatusService from "../services/StatusService";
import MapQueuesService from "../services/MapQueuesService";


export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      list: [],
      user: {},
      user_id: 0,
      title: "",
      types:[],
      times:{},
      token:'',
      status:'',
      queue:0,
      licenselist:[],
      licensestatus:false,
      show:false,
      start:'',
      end:''
    };
  },
  async mounted() {
    // console.log(this.currentUser);

    await this.gettime()
   
    this.getTypes()
this.getstatus()
this.getqueue()
// console.log(this.currentUser);

this.getTypes()
//     if (this.currentUser) {
//       await UserService.getUser(this.currentUser.id).then(async (res) => {
//           localStorage.removeItem('userqueuevidm');
//               localStorage.setItem('userqueuevidm', JSON.stringify(res.data));
//       })
//       if (this.currentUser.line_token == null) {
//         this.token = this.currentUser.token
//         this.$router.absUrl(open(this.urlAuth()))
//         // window.open(this.urlAuth(), '_blank');
//     }else{
//     this.getTypes()
// await this.gettime()
//     }
//     }
//     else{
//       this.token = this.generateGuid()
//      await UserService.getgetuid(this.getUUID()).then(async (res) => {
//         console.log(res.data);
//         if (res.data) {
//           await UserService.getUser(res.data.id).then(async (res) => {
//           localStorage.removeItem('userqueuevidm');
//               localStorage.setItem('userqueuevidm', JSON.stringify(res.data));
//               setTimeout(function () {
//           location.reload();
//         }, 500);
//       });
//         }else{
//           var user = {
//             firstname:null,
//             lastname:null,
//             email:null,
//             line_token:null,
//             phone:null,
//               role_id: 2,
//               active: 1,
//               token: this.token,
//               uid: this.getUUID(),
//               password:'1234'
//             };
//             // console.log(user);
//             await UserService.createUser(user).then(async (res) => {
//         //       setTimeout(function () {
//         //   location.reload();
//         // }, 500);
//             //  await UserService.getUser(res.data.id).then(async (res)=>{
//             //   await localStorage.removeItem('userqueuevidm');
//             //   await localStorage.setItem('userqueuevidm', JSON.stringify(res.data));
//               // await document.getElementById("myCheck").click();
//               await UserService.getUser(res.data.id).then(async (res) => {
//                 this.token = res.data.token
//         console.log(res.data);
//           localStorage.removeItem('userqueuevidm');
//               localStorage.setItem('userqueuevidm', JSON.stringify(res.data));
//               // document.getElementById("myCheck").style.display == "block"
//               alert('test')
//               window.open(this.urlAuth(), '_blank');
//               })
//             })
//         }
//       }); 
//     }
  },
  methods: {
    getlicense(){ 
      this.licenselist = []
TypesService.gettype(this.user.typeId).then((res)=>{
        // this.licenselist = res.data.count
        console.log(this.licenselist);
        for (let r = 0; r < res.data.count; r++) {
          this.licenselist.push({
            license:'',
            no:r+1
          })
          
        }
        this.licensestatus = true
      })
    },
    getqueue(){
      QueuesService.getqueues('','').then((res)=>{
        this.queue = res.data.length
        console.log(this.queue);
      })
    },
    getstatus(){
      StatusService.getstatuss(1).then((res)=>{
        this.status = res.data[0]
        // console.log(this.status);
      })
    },
    getUUID(){  
    var uuid = new DeviceUUID().get();
  return uuid
   },
    generateGuid() {
      var result, i, j;
      result = '';
      for (j = 0; j < 32; j++) {
        if (j == 8 || j == 12 || j == 16 || j == 20)
          result = result + '-';
        i = Math.floor(Math.random() * 16).toString(16).toUpperCase();
        result = result + i;
      }
      return result;
    },
    urlAuth() {
      var clientId = 'm3vP7rWNzTcK3CCOi3yabf'
      var engine = LinkImageService.getLinkFrontend() + '/line'
      return `https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${engine}&scope=notify&state=${this.token}`
    },
    connectline(){
      window.open(this.urlAuth());
    },
    
    gettime(){
      TimesService.gettime(1).then((res)=>{
        this.times = res.data
        // console.log(this.times.starttime);
        // console.log(this.times.endtime);
        var st = this.times.starttime.split(':')
        var et = this.times.endtime.split(':')
        var hst = st[0]
        var mst = st[1]
        var het = et[0]
        var met = et[1]
        this.start = hst+'.'+mst
        this.end = het+'.'+met
        // console.log(hst,mst,het,met);
    var d = new Date();
var start = new Date(d.getFullYear(), d.getMonth(), d.getDate(),  hst, mst); // 9:00 AM
var end = new Date(d.getFullYear(), d.getMonth(), d.getDate(), het, met); // 5:00 PM
// console.log(d.getDate());
// console.log(d.getMonth()+1);
// console.log(d.getFullYear());
// console.log(start);
// console.log(end);
// the following is to handle cases where the times are on the opposite side of
// midnight e.g. when you want to get the difference between 9:00 PM and 5:00 AM

if (d >= start && d < end) {
    this.show = true
}else{
  this.show = false
}
      })
    },
    getTypes(){
      TypesService.gettypes(1).then((res)=>{
        this.types = res.data
      })
    },
    getUsers(){
      TimesService.gettime(this.user_id).then((res) => {
          // console.log(res.data);
          this.user = res.data;
        });
    },
    getnameq(no){
      var str = String(no)
      // console.log(str.length);
      // console.log(this.times);
      var count = this.times.count - str.length
      var name = this.times.queueno
      // console.log(count);
      for (let s = 0; s < count; s++) {
        name += '0'
      }
      name+=no
      return name
      // console.log(name);
    },
    sentline(txt,line_token) {
      console.log(line_token);
        LinkImageService.sendNotify(
          txt,
          line_token
        );
    },
    async save() {
var chk = false
for (let l = 0; l < this.licenselist.length; l++) {
  if (this.licenselist[l].license != null && this.licenselist[l].license != '') {
    chk = true
  }
}
      if (this.user.typeId == "") {
        alert("กรุณาเลือกประเภททรถ");
      }else if (chk == false) {
        alert("กรุณากรอกหมายเลขทะเบียนอย่างน้อย 1 คัน");
      }else {
        var userdata = {
          nameQueue:'Q001',
          userId:this.currentUser.id,
          typeId:this.user.typeId,
          statusId:this.status.id,
          updateBy:this.currentUser.id,
        };
        if (this.user_id == 0) {
this.getqueue()
    // QueuesService.getnameQueue(this.user.typeId).then((res)=>{
    //         // console.log(res.data);
    //         var queu = res.data.length
    //         TypesService.gettype(this.user.typeId).then((res)=>{
    //           // console.log(res.data.count);
    //    if (queu == res.data.count) {
    //     alert('ไม่สามารถจองคิวได้ เนื่องจาก'+ res.data.name +' ครบกำหนดจองคิวต่อวันแล้ว')
    //    }else{
      // console.log(this.queue);
userdata.nameQueue = await this.getnameq(this.queue+1)
// console.log(userdata);
// console.log(this.licenselist);
        await QueuesService.createqueue(userdata).then(async (res) => {
          var queueId = res.data.id
          for (let l = 0; l < this.licenselist.length; l++) {
            if (this.licenselist[l].license != '') {
              var map = {
              queueId:queueId,
              name:this.licenselist[l].license,
              no:this.licenselist[l].no,
            }
            await MapQueuesService.createmap_queues(map).then(()=>{

            })
            }
            if (l+1 == this.licenselist.length) {
              console.log(queueId);
              QueuesService.getqueue(queueId).then((res)=>{
          var txt = res.data.license+res.data.status
        this.sentline(txt,res.data.line_token) 
        console.log(txt);
          alert('บันทึกสำเร็จ')
            this.getUsers();
            this.licenselist = []
            this.user = {}
            this.licensestatus = false
          })
}
          }
      //     })
      //  }
      // })
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        } else {
          // console.log(this.user_id);
          QueuesService.updatequeue(this.user_id, userdata).then(() => {
            // console.log(res.data);
            alert('บันทึกสำเร็จ')
            this.getUsers();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        }
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
